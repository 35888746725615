import { $http, API_ENDPOINTS } from './axios';
import * as queryString from 'querystring';

export function getAllSubscriptionApi() {
  return $http.get(API_ENDPOINTS.SUBSCRIPTIONS);
}

export function getSubscriptionInfoApi(id: string) {
  return $http.get(API_ENDPOINTS.GET_SUBSCRIPTIONS.replace(':id', id));
}

export function getApi(endpoint: string, query?: queryString.ParsedUrlQuery) {
  return $http.get(endpoint + '?' + queryString.stringify(query));
}

export function verifyTicketTransaction(payload: any) {
  return $http.get(API_ENDPOINTS.TICKET_VERIFICATION, {
    params: payload,
  });
}
