import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button/Button';
import CustomInput from '../../components/Input/customInput';
import NewHeader from '../../components/NewHeader';
import style from './styles.module.scss';
import Tv from '../../assets/waitinglist-frame.webp';
import { ReactComponent as Play } from '../../assets/play.svg';
import { useWindowSize } from 'usehooks-ts';
import { validateWaitlistForm } from './config';
import WaitlistModal from '../../components/WaitlistModal';
import { addToMailingListApi } from '../../shared/api/mailingList';
import useAnalytics from '../../hooks/useAnalytics';
import {verifyTicketTransaction} from "../../shared/api/subscriptions";
import { getEnv } from '../../utils';

export interface IWaitlist {}

const Waitlist: React.FunctionComponent<IWaitlist> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [reference, setTransaction] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const { width } = useWindowSize();
  const { track } = useAnalytics();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    track('verify-ticket', {
      event: 'clicked on verify',
      formData: { reference },
    });
    setError('');
    setLoading(true);

    try {
      await verifyTicketTransaction({ reference });
      track('verify-ticket', {
        event: 'request success ticket',
        formData: { reference },
      });

      window.location.href = `${getEnv('REACT_APP_USER_URL')}/view-tickets/${reference}?reference=` + reference

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const message = error?.response?.data?.errorInfo || 'Something went wrong! Try again later'
      setError(message);
      track('verify-ticket', {
        event: 'request failed ticket',
        formData: { reference, message },
        error: error.response,
      });
    }
  };

  console.log(reference, 'reference')
  return (
    <div className={style.wrapper}>
      <div className={clsx('absolute', style.gg)}>
        <NewHeader hidden contactUs className={style.header} />
      </div>

      <div className={style.left}>
        <h1 className={style.h1}>
          Event Ticket Verification
        </h1>
        <form className={style.form} onSubmit={handleSubmit}>
          <p className={clsx(style.subtitle, style.join)}>
            Copy and paste the transaction id from your email to verify.
          </p>

          <CustomInput
            value={reference}
            setValue={setTransaction}
            type={'text'}
            className={style.input}
            placeholder="Transaction Id: eg. T6XXXXXXXXXXXX"
            successClassName=""
          />

            {error && <span style={{ marginTop: -10 }} className={style.error}>{error}</span>}

          <Button
            loading={loading}
            disabled={loading || !reference || reference?.length < 6}
            className={style.button}
            primary
          >
            Verify Transaction
          </Button>
        </form>

        <div className={clsx('flex', style['button-area'])}></div>
      </div>

      <div className={style.right}>
        {width > 696 && (
          <div className={clsx('relative', style['tv-area'])}>
            <div className={style.tv}>
              <img src={Tv} alt={'demo video'} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Waitlist;
