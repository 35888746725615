import { passwordStrength } from 'check-password-strength';
import React from 'react';

type IValidatePassword = {
  type?: React.HTMLInputTypeAttribute | 'phonenumber' | 'otp';
  inbuiltPasswordValidate?: boolean;
  e: any;
  setBarLength: React.Dispatch<React.SetStateAction<number>>;
  setPasswordError: React.Dispatch<React.SetStateAction<boolean>>;
  setError?: React.Dispatch<React.SetStateAction<string>>;
};

export const validatePassword = ({
  type,
  e,
  inbuiltPasswordValidate,
  setBarLength,
  setPasswordError,
  setError,
}: IValidatePassword) => {
  const errorMessage =
    'Password must have an uppercase, lowercase, symbol, number and be at least 8 characters long';

  if (type === 'password' && e.target.value && inbuiltPasswordValidate) {
    if (passwordStrength(e.target.value).value === 'Too weak') {
      setBarLength(20);
      setPasswordError(true);
      setError && setError(errorMessage);
    }
    if (passwordStrength(e.target.value).value === 'Weak') {
      setBarLength(30);
      setPasswordError(true);
      setError && setError(errorMessage);
    }
    if (passwordStrength(e.target.value).value === 'Medium') {
      setBarLength(50);
      setPasswordError(false);
      setError && setError('');
    }
    if (
      passwordStrength(e.target.value).value === 'Strong' &&
      passwordStrength(e.target.value).length < 15
    ) {
      setBarLength(80);
      setPasswordError(false);
      setError && setError('');
    }
    if (
      passwordStrength(e.target.value).value === 'Strong' &&
      passwordStrength(e.target.value).length >= 15
    ) {
      setBarLength(100);
      setPasswordError(false);
      setError && setError('');
    }
  } else {
    setBarLength(0);
    setPasswordError(false);
  }
};

export const checkIfInputNumber = (value: string) => {
  if (isNaN(Number(value)) && value !== '') {
    return false;
  } else {
    return true;
  }
};
