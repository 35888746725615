import { ILinks } from '../../interface/links';
import routes from '../../routes';


export const resourcesDropdownData: ILinks[] = [
  {
    name: 'Kirgawa Guide',
    description:
      'Obtain a lots and lots of useful tips in order to make the most of Kirgawa.',
    to: '',
  },
  {
    name: 'Support',
    description:
      'Need Help? Get in touch with Our Friendly Customer Support Team.',
    to: routes.CONTACT,
  },
  {
    name: 'Partners',
    description:
      'Want to become a partner? learn more about our partnership programs.',
    to: routes.CONTACT,
  },
  {
    name: 'Developers & API',
    description:
      'Learn more about building teams and events on the kirgawa app.',
    to: '//documenter.getpostman.com/view/6559442/2s8YzL4mLp',
  },
  // {
  //   name: 'Accesibility',
  //   description:
  //     'Learn from the kirgawa team about how we build apps for all people.',
  //   to: '',
  // },
  // {
  //   name: 'Blog',
  //   description:
  //     'Discover the latest Kirgawa features and company news',
  //   to: '',
  // },
  {
    name: 'Community',
    description: 'Connect With And Learn From Kirgawa Users Around The World.',
    to: '//join.slack.com/t/emmsdan/signup',
  },
  {
    name: 'About Us',
    description:
      'Learn more about our goals and mission.',
    to: routes.ABOUT_US,
  },
];

export const demoDropdownData: ILinks[] = [
  // {
  //   name: 'Stage Timer',
  //   description:
  //     'Manage how long presenters are spending on stage, from anywhere in the world.',
  //   to: '',
  // },
  // {
  //   name: 'Create Events',
  //   description:
  //     'Create Dynamic Events and advance event features, easily and effortlessly.',
  //   to: '',
  // },
  {
    name: 'Request Advance Demo',
    description:
      'Learn more about other features, use cases and how its the perfect fit for you.',
    to: routes.CONTACT,
  },
];
