import axios, { AxiosResponse } from 'axios';
import { clearUserSessionStore, getLoginToken } from '../utils';

export let baseURL = process.env.REACT_APP_API_URL as string;
export const BASE_URL = baseURL;
export const API_ENDPOINTS = {
  LOGIN: 'auth/login',
  REGISTER: 'auth/signup',
  VERIFYOTP: 'auth/verify',
  RESENDOTP: 'auth/resend',
  GET_USER_DATA: 'auth/callback/public',
  FORGOT_PASSWORD: 'auth/forgot-password',
  CHANGE_PASSWORD: 'auth/change-password',
  SUBSCRIPTIONS: 'pub/subscriptions',
  GET_SUBSCRIPTIONS: 'pub/subscriptions/:id',
  ACTIVITY: 'activity',
  GET_ALL_ACTIVITY: 'activity',
  GET_ACTIVITY: 'pub/activity/:id',
  UPDATE_ACTIVITY: 'activity/update',
  MAILING_LIST: 'mailing-list',
  MEDIA_FILE: 'media',
  USERS: 'users',
  TICKET_VERIFICATION: '/pub/payment/ticket/paystack/verify',
};
export default axios;
export const $http = axios.create({
  baseURL,
});
$http.interceptors.request.use(config => {
  if (config.url !== API_ENDPOINTS.LOGIN) {
    config.headers = {
      ...config.headers,
      Authorization: getLoginToken(),
    };
  }
  console.log('request');
  return config;
});

$http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  axiosResponse => {
    const resp = axiosResponse.response;

    if (
      resp.status === 401 ||
      (resp.data && resp.data.error && resp.data.error.redirectTo)
    ) {
      clearUserSessionStore();
      setTimeout(() => {
        window.location.href = '/auth/login';
      }, 2000);
    }
    throw axiosResponse;
  }
);

export interface MetaData {
  current_page: number;
  first_page: number;
  last_page: number;
  per_page: number;
  total: number;
}
