import React from 'react';
import style from './styles.module.scss';
import { ReactComponent as Logo } from '../../assets/logo-2.svg';
import { ReactComponent as Arrow } from '../../assets/arrow-down.svg';
import { ReactComponent as Hamburger } from '../../assets/hamburger.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import routes from '../../routes';
import Button from '../Button/Button';
import useCurrentPage from '../../utils/currentPage';
import { useWindowSize } from 'usehooks-ts';
import HeaderDropdown from '../HeaderDropdown';
import { demoDropdownData, resourcesDropdownData } from './config';
import { random } from 'lodash';

export interface INewHeader {
  hidden?: boolean;
  className?: string;
  hideLinks?: boolean;
  contactUs?: boolean;
}

const NewHeader: React.FunctionComponent<INewHeader> = ({
  hidden,
  className,
  hideLinks,
  contactUs,
}) => {
  const { home, features, pricing } = useCurrentPage();
  const [openMobile, setOpenMobile] = React.useState(false);
  const { width } = useWindowSize();

  const demoId = 'col' + String(random(10000));
  const resourseId = 'col' + String(random(10000));

  return (
    <div className={clsx(style.wrapper, className)}>
      <Link aria-label='home logo' to={routes.HOME}>
        <Logo className={style.logo} />
      </Link>

      {contactUs && (
        <Button
          ariaLabel="contact us"
          type="link"
          to={routes.CONTACT}
          primary
          className={style.but}
        >
          Contact Us
        </Button>
      )}

      {!hidden && (
        <>
          {width > 800 && (
            <>
              {!hideLinks && (
                <ul className={style.ul}>
                  <Link to={routes.HOME}>
                    <li className={clsx(style.li, { [style.active]: home })}>
                      Home
                    </li>
                  </Link>
                  <Link to={routes.FEATURES}>
                    <li
                      className={clsx(style.li, { [style.active]: features })}
                    >
                      Features
                    </li>
                  </Link>
                  <div className="dropdown relative">
                    <li
                      className={clsx(
                        style.li,
                        'dropdown-toggle',
                        style.dropdown
                      )}
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                    >
                      Demo <Arrow className={style.arrow} />
                    </li>
                    <HeaderDropdown linksData={demoDropdownData} />
                  </div>
                  <Link to={routes.PRICING}>
                    <li className={clsx(style.li, { [style.active]: pricing })}>
                      Pricing
                    </li>
                  </Link>
                  <div className="dropdown relative">
                    <li
                      className={clsx(
                        style.li,
                        'dropdown-toggle',
                        style.dropdown
                      )}
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                    >
                      Resources <Arrow className={style.arrow} />
                    </li>
                    <HeaderDropdown linksData={resourcesDropdownData} />
                  </div>
                </ul>
              )}

              <ul className={style['btns-area']}>
                <Button
                  type="link"
                  to={routes.LOGIN}
                  light
                  noBorder
                  className={style.but}
                >
                  Log in
                </Button>
                <Button
                  type="link"
                  to={routes.REGISTER}
                  primary
                  className={style.but}
                >
                  Sign up
                </Button>
              </ul>
            </>
          )}

          <Button
            ariaLabel="mobile menu button"
            className={clsx(style['icon-button'])}
            onClick={() => setOpenMobile(true)}
            light
            noBorder
          >
            <Hamburger className={style['hamburger']} />
          </Button>
        </>
      )}

      {width <= 800 && openMobile && !hidden ? (
        <div
          className={clsx(
            'absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right',
            style.hiddenMob
          )}
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-1 flex items-center justify-between">
              <div>
                <Logo className={style.logo} />
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  onClick={() => setOpenMobile(!openMobile)}
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span className="sr-only">Close main menu</span>

                  <Close className={style['hamburger']} />
                </button>
              </div>
            </div>
            {!hideLinks && (
              <div tabIndex={0} className="px-2 pt-2 pb-3 space-y-1">
                <Link
                  to={routes.HOME}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  Home
                </Link>

                <Link
                  to={routes.FEATURES}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  Features
                </Link>

                <a
                  className="flex px-3 py-2 rounded-md text-base items-center font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  data-bs-toggle="collapse"
                  href={'#' + demoId}
                  role="button"
                  aria-expanded="false"
                  aria-controls={demoId}
                >
                  Demo <Arrow className={style.arrow} />
                </a>
                <HeaderDropdown linksData={demoDropdownData} id={demoId} />

                <Link
                  to={routes.PRICING}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  Pricing
                </Link>
                <a
                  className="flex px-3 py-2 rounded-md text-base items-center font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  data-bs-toggle="collapse"
                  href={'#' + resourseId}
                  role="button"
                  aria-expanded="false"
                  aria-controls={resourseId}
                >
                  Resources <Arrow className={style.arrow} />
                </a>
                <HeaderDropdown
                  linksData={resourcesDropdownData}
                  id={resourseId}
                />
              </div>
            )}

            <Button
              ariaLabel="link"
              type="link"
              to={routes.LOGIN}
              light
              noBorder
              className={clsx(style.but, style.mobBut)}
            >
              Log in
            </Button>
            <Button
              ariaLabel="link"
              type="link"
              to={routes.PRICING}
              primary
              className={clsx(style.but, style.mobBut)}
            >
              Sign up
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NewHeader;
