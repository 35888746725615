import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import style from './styles.module.scss';
import { ReactComponent as Spinner } from '../../assets/spinner.svg';

export interface ICustomButton {
  primary?: boolean;
  secondary?: boolean;
  dark?: boolean;
  light?: boolean;
  success?: boolean;
  colored?: boolean;
  noBorder?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
  label?: string;
  height?: number;
  iconAfter?: any;
  iconBefore?: any;
  children?: any;
  width?: string;
  className?: string;
  color?: string;
  type?: 'button' | 'link';
  to?: string;
  auth?: boolean;
  active?: boolean;
  onClick?: (event?: React.FormEvent<Element>) => void;
  ariaLabel?: string;
  iconClassName?: string;
  [key: string]: any;
}

export const Button = React.forwardRef(({
  type = 'button',
  children,
  to,
  className,
  auth,
  active = true,
  loading,
  iconAfter,
  iconBefore,
  primary,
  dark,
  secondary,
  light,
  noBorder,
  disabled,
  ariaLabel,
  iconClassName,
  fullWidth,
  colored,
  ...props
}: ICustomButton, ref) => {
  React.useEffect(() => { }, []);
  const inClassName = clsx(
    style['custom-button'],
    {
      'px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-slate-600 hover:bg-slate-900':
        !primary && !dark && !secondary && !light,
      'w-full font-bold rounded-full transition duration-200 px-8 py-3 ': auth,
      'md:text-slate-200 md:hover:bg-slate-900 md:bg-slate-900 md:hover:bg-slate-900':
        !disabled && !primary && !dark && !secondary && !light,
      'w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md md:text-slate-700 bg-slate-100 hover:bg-slate-200 md:bg-slate-100 md:hover:bg-slate-200 md:py-4 md:text-lg':
        !active,
    },
    className,
    { [style.primary]: primary },
    { [style.colored]: colored },
    { [style.dark]: dark },
    { [style.secondary]: secondary },
    { [style.light]: light },
    { [style['no-border']]: noBorder },
    { [style['full-width']]: fullWidth }
  );
  return type === 'link' && to ? (
    <Link {...props} to={to} className={inClassName}>
      {!loading && (
        <>
          {iconBefore && (
            <div className={clsx(style['b-icon'], style['left'])}>
              {iconBefore}
            </div>
          )}
          {children}
          {iconAfter && (
            <div className={clsx(style['b-icon'], style['right'])}>
              {iconAfter}
            </div>
          )}
        </>
      )}
      {loading && <Spinner />}
    </Link>
  ) : (
    <button ref={ref as any} disabled={disabled} {...props} aria-label={ariaLabel} className={inClassName}>
      {!loading && (
        <>
          {iconBefore && (
            <div className={clsx(style['b-icon'], style['left'], iconClassName)} data-class="icon-before">
              {iconBefore}
            </div>
          )}
          {children}
          {iconAfter && (
            <div className={clsx(style['b-icon'], style['right'])}>
              {iconAfter}
            </div>
          )}
        </>
      )}
      {loading && <Spinner />}
    </button>
  );
})

export default Button;
