import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import './styles.scss';

export interface IProgressBar {
  total: number;
  value: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  className?: string
  margin?: string
}

const ProgressBar: React.FunctionComponent<IProgressBar> = ({
  total,
  value,
  backgroundColor = 'rgba(41, 126, 175, 0.2)',
  color = 'red',
  height,
  className,
  margin,
}) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const getPercentage = (value / total) * 100;
    setPercentage(getPercentage);
  }, [total, value]);
  return (
    <div className={clsx("progress-bar", className)} style={{ backgroundColor: backgroundColor, height, margin }}>
        <div
          className="child-bar"
          style={{ backgroundColor: color, width: `${percentage}%` }}
        ></div>
    </div>
  );
};

export default ProgressBar;
