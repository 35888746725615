import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { ILinks } from '../../interface/links';
import style from './styles.module.scss';

export interface IHeaderDropdown {
  linksData: ILinks[];
  id?: string;
}

const HeaderDropdown: React.FunctionComponent<IHeaderDropdown> = ({
  linksData,
  id,
}) => {
  const { width } = useWindowSize();

  return (
    <>
      {width > 800 && (
        <div
          className={clsx(
            `dropdown-menu 
            py-2 
            bottom-6
            min-w-full
            w-screen 
            absolute 
            hidden 
            bg-white
            `,
            style['header-dropdown']
          )}
          aria-labelledby="dropdownMenuButton2"
        >
          <hr className="h-[1px] bg-downy w-full mb-8" />
          <ul
            className={`flex 
          flex-wrap 
          gap-x-[100px]
          tracking-[0.04em] 
          gap-y-[4.125rem] 
          pb-[4.125rem]
          `}
          >
            {linksData.map((el, i) => (
              <NavLink
                to={el.to}
                key={i}
                className={clsx(style.button, {
                  [style.hidden]:
                    el.name === 'Create Events' || el.name === 'Stage Timer' || el.name === 'Accesibility'|| el.name === 'Blog',
                })}
              >
                <li
                  className={clsx(
                    `dropdown-item 
                    text-sm 
                    font-poppins 
                    py-2 
                    px-0
                    font-normal 
                    max-w-[295px]
                    bg-transparent 
                    transition-transform
                    text-gray-700 
                    hover:scale-105
                    active:bg-white
                    capitalize
                    bg-white`
                  )}
                >
                  <div className="text-[18px] font-poppins font-medium text-charcoal mb-5">
                    {el.name}
                  </div>
                  <p className="text-ash text-[14px] leading-[1.5rem] ">
                    {el.description}
                  </p>
                </li>
              </NavLink>
            ))}
          </ul>
        </div>
      )}

      {width < 800 && (
        <ul
          className={clsx('collapse', style['collapse-drop-down'])}
          id={id ?? 'collapseExample'}
        >
          {linksData.map((el, i) => (
            <NavLink
              to={el.to}
              key={i}
              className={clsx(style.button, {
                [style.hidden]:
                  el.name === 'Create Events' || el.name === 'Stage Timer' || el.name === 'Accesibility'|| el.name === 'Blog',
              })}
            >
              <li
                className={clsx(
                  `pl-6 
                pr-3 
                bg-red 
                py-1 
                font-normal 
                hover:text-gray-900 
                hover:bg-gray-50 
                rounded-md 
                text-sm 
                block
                `,
                  { 'mb-1': i !== linksData.length - 1 }
                )}
              >
                {el.name}
              </li>
            </NavLink>
          ))}
        </ul>
      )}
    </>
  );
};

export default HeaderDropdown;
