import clsx from 'clsx';
import React, { useState } from 'react';
import CountriesOptions from '../CountriesOptions';
import ProgressBar from '../ProgressBar';
import style from './styles.module.scss';
import { ReactComponent as HidePassword } from '../../assets/hide-password.svg';
import { ReactComponent as ShowPassword } from '../../assets/show-password.svg';
import { random } from 'lodash';
import OtpInput from '../ReactOTP';
import { checkIfInputNumber, validatePassword } from './config';

export interface ICustomInput {
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  error?: string;
  onChange?: (e: any) => void;
  className?: string;
  successClassName?: string;
  type?: React.HTMLInputTypeAttribute | 'phonenumber' | 'otp';
  label?: string | React.ReactNode;
  placeholder?: string;
  inbuiltPasswordValidate?: boolean;
  numInput?: number;
  isInputNum?: boolean;
  ref?: any;
  name?: string;
  setError?: React.Dispatch<React.SetStateAction<string>>;
  countryCode?: string
  setCountryCode?: React.Dispatch<React.SetStateAction<string>>;
  checkboxValue?: boolean,
  setCheckboxValue?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomInput: React.FunctionComponent<ICustomInput> = ({
  className,
  error,
  onChange,
  setValue,
  value,
  label,
  type = 'text',
  placeholder,
  numInput = 6,
  isInputNum = true,
  inbuiltPasswordValidate = true,
  ref,
  name,
  setError,
  countryCode,
  setCountryCode,
  checkboxValue,
  setCheckboxValue,
  successClassName,
}) => {
  const [passwordError, setPasswordError] = useState(false);
  const [barLength, setBarLength] = useState(0);
  const [showPasswordToggle, setshowPasswordToggle] = useState(false);
  const [passwordType, setPasswordType] =
    useState<React.HTMLInputTypeAttribute>('password');
  const id = random(10000);

  const handleNumberChange = (e: any) => {
    if (!setValue) return;

    if(!checkIfInputNumber(e.target.value)) return

    setValue(e.target.value);
  };

  const handleChange = (e: any) => {
    if (!setValue) return;

    validatePassword({
      type,
      e,
      inbuiltPasswordValidate,
      setBarLength,
      setPasswordError,
      setError,
    });

    setValue(e.target.value);
  };

  const handleOtpChange = (otp: string) => {
    if (!setValue) return;

    setValue(otp);
  };
  return (
    <div
      className={clsx(style['custom-input'], 'flex', className, {
        [style['checkbox-cont']]: type === 'checkbox',
      })}
    >
      {type === 'checkbox' && (
        <input
          className={style.checkbox}
          type="checkbox"
          checked={checkboxValue}
          id={id.toString()}
          ref={ref}
          name={name}
          autoComplete='on'
          onChange={(e) => setCheckboxValue && setCheckboxValue(e.target.checked)}
        />
      )}

      {label && (
        <label
          htmlFor={id.toString()}
          className={clsx('font-medium text-charcoal mb-3', {
            [style['checkbox-label']]: type === 'checkbox',
          })}
        >
          {label}
        </label>
      )}
      {type !== 'phonenumber' && type !== 'checkbox' && type !== 'otp' && (
        <>
          <div className="relative">
            <input
              className={clsx(
                style.input,
                'bg-app-grey-100',
                { [successClassName ?? style.success]: value },
                { [style.error]: error || passwordError }
              )}
              type={type === 'password' ? passwordType : type}
              value={value}
              placeholder={placeholder}
              id={id.toString()}
              onChange={onChange ? onChange : handleChange}
              ref={ref}
              name={name}
              autoComplete='on'
              onFocus={() => setshowPasswordToggle(true)}
              onBlur={() => (!value ? setshowPasswordToggle(false) : '')}
            />
            {type === 'password' && showPasswordToggle && (
              <>
                {passwordType === 'password' && (
                  <HidePassword
                    className={style.toggle}
                    onClick={() => setPasswordType('text')}
                  />
                )}
                {passwordType === 'text' && (
                  <ShowPassword
                    className={style.toggle}
                    onClick={() => setPasswordType('password')}
                  />
                )}
              </>
            )}
          </div>

          {type === 'password' && inbuiltPasswordValidate && (
            <ProgressBar
              total={100}
              value={barLength}
              backgroundColor="inherit"
              height={8}
              color={barLength < 50 ? '#C01818' : '#06B169'}
              margin="10px 0 0 0"
            />
          )}
        </>
      )}

      {type === 'phonenumber' && (
        <div className="relative z-10">
          <CountriesOptions
            className={style.countries}
            setCountryCode={setCountryCode}
          />
          <span className={style.dial}>({countryCode})</span>
          <input
            className={clsx(
              style.input,
              style.phone,
              'bg-app-grey-100 w-full',
              { [style.success]: value },
              { [style.error]: error }
            )}
            type={'tel'}
            id={id.toString()}
            ref={ref}
            autoComplete='on'
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange ? onChange : handleNumberChange}
          />
        </div>
      )}

      {type === 'otp' && (
        <OtpInput
          value={value}
          onChange={handleOtpChange}
          numInputs={numInput}
          isInputNum={isInputNum}
          containerStyle={style['otp-container']}
          inputStyle={clsx(style['otp-inputs'])}
        />
      )}

      {error && (
        <small className={clsx(style['error-text'], 'text-danger')}>
          {error}
        </small>
      )}
    </div>
  );
};

export default CustomInput;
