import clsx from 'clsx';
import React, { useEffect } from 'react';
import style from './styles.module.scss';
import { countries } from './test';
import { ReactComponent as ArrowDown } from '../../assets/arrow-down-bold.svg';

export interface ICountriesOptions {
  setCountryCode?: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
}

const CountriesOptions: React.FunctionComponent<ICountriesOptions> = ({
  setCountryCode,
  className,
}) => {
  const [activeObject, setActiveObject] = React.useState<any>(countries[0]);

  useEffect(() => {
    if (!setCountryCode) return;
    setCountryCode(activeObject.dial);
  }, [activeObject.dial, setCountryCode]);
  return (
    <div className={clsx(style.wrapper, 'relative dropdown', className)}>
      <button
        className={clsx(style.button, 'dropdown-toggle')}
        id="dropdownMenuButton2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {activeObject.flag}
        <ArrowDown className={style.arrow} />
      </button>

      <div
        className={clsx(
          ` 
            dropdown-menu 
            min-w-max 
            absolute 
            text-base 
            z-[10000] 
            float-left 
            list-none 
            font-raleway 
            bg-white
            text-left 
            shadow-lg 
            mt-1 
            hidden 
            m-0 
            w-full
            rounded-[5px]
            bg-clip-padding 
            `
        )}
        aria-labelledby="dropdownMenuButton2"
      >
        {countries.map((el, i) => (
          <div
            className={style.optn}
            role="menuitem"
            id="menu-item-0"
            key={i + 'country'}
            onClick={() => setActiveObject(el)}
          >
            {el.flag}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountriesOptions;
