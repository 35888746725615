import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import routes from './routes';
import clsx from 'clsx';
import './App.scss';
import SuspenseLoader from './components/SuspenseLoader';
import useAnalytics from './hooks/useAnalytics';
import { ExampleApp } from "./Example";
import TransactionForm from "./screen/Waitlist/VerifyTransaction";

function App() {
  const WaitList = lazy(() => import('./screen/Waitlist'));
  const NewLanding = lazy(() => import('./screen/NewLanding'));
  const AccountActivation = lazy(
    () => import('./components/AccountActivation')
  );
  const NewRegister = lazy(() => import('./screen/Auth/NewRegister'));
  const NewPricing = lazy(() => import('./screen/NewPricing'));
  const NewLogin = lazy(() => import('./screen/Auth/NewLogin'));
  const LoginComponent = lazy(() => import('./components/LoginComponent'));
  const CreatePassword = lazy(() => import('./components/CreatePassword'));
  const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
  const ResetPasswordOTP = lazy(() => import('./components/ResetPasswordOTP'));
  const VerifyEmail = lazy(() => import('./components/VerifyEmail'));
  const ComingSoon = lazy(() => import('./screen/ErrorComingSoon'));
  const UnauthourizedError = lazy(() => import('./screen/Error401'));
  const ServerError = lazy(() => import('./screen/Error500'));
  const PaymentErrorRequired = lazy(() => import('./screen/Error402'));
  const Error = lazy(() => import('./screen/Error404'));
  const Contact = lazy(() => import('./screen/Contact'));
  const Features = lazy(() => import('./screen/Features'));
  const AboutUs = lazy(() => import('./screen/AboutUs'));
  const SocialAuthCallback = lazy(() => import('./screen/SocialAuthCallback'));
  const PrivacyPolicy = lazy(() => import('./screen/AboutUs/Privacy'));
  const TermsAndCondition = lazy(
    () => import('./screen/AboutUs/TermsAndCondition')
  );
  const BotAppLogin = lazy(
    () => import('./screen/Auth/BotAppLogin')
  );
  const { track } = useAnalytics();
  const { pathname } = useLocation();

  useEffect(() => {
    track('Pageview', { page: pathname });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div>
      <Suspense fallback={<SuspenseLoader fullscreen={true} />}>
        <div className={clsx('m-auto')}>
          <Routes>
            <Route path={'verify'} element={<TransactionForm />} />
            <Route path={'/routes.LANDING'} element={<ExampleApp />} />
            <Route path={routes.LANDING} element={<NewLanding />} />
            <Route path={routes.HOME} element={<NewLanding />} />
            <Route path={routes.HOME+'/wait'} element={<WaitList />} />
            <Route path={''} element={<NewLanding />} />
            <Route path={routes.AUTH} element={<AccountActivation />} />
            <Route path={routes.REGISTER} element={<NewRegister />} />
            <Route path={routes.PRICING} element={<NewPricing />} />
            <Route path={routes.AUTH} element={<NewLogin />}>
              <Route path={routes.LOGIN} element={<LoginComponent />} />
              <Route
                path={routes.CREATE_PASSWORD}
                element={<CreatePassword />}
              />
              <Route
                path={routes.FORGOT_PASSWORD}
                element={<ForgotPassword />}
              />
              <Route
                path={routes.RESET_PASSWORD}
                element={<ResetPasswordOTP />}
              />
              <Route path={routes.VERIFY_EMAIL} element={<VerifyEmail />} />
              <Route path={routes.BOT_APP_LOGIN} element={<BotAppLogin />} />
            </Route>
            <Route path={routes.COMING_SOON} element={<ComingSoon />} />
            <Route path={routes.ERROR_401} element={<UnauthourizedError />} />
            <Route path={routes.ERROR_500} element={<ServerError />} />
            <Route path={routes.ERROR_402} element={<PaymentErrorRequired />} />
            <Route path={routes.CONTACT} element={<Contact />} />
            <Route path={routes.FEATURES} element={<Features />} />
            <Route path={routes.ABOUT_US} element={<AboutUs />} />
            <Route path={routes.PRIVACY} element={<PrivacyPolicy />} />
            <Route path={routes.TERMS} element={<TermsAndCondition />} />
            <Route path={'*'} element={<Error />} />
            <Route path={routes.GOOGLE_AUTH} element={<SocialAuthCallback />} />
            <Route path={routes.GOOGLE_AUTH2} element={<SocialAuthCallback />} />
          </Routes>
        </div>
      </Suspense>
    </div>
  );
}

export default App;
