import Capitalize from 'lodash/capitalize';
import { LOCAL_STORAGE_USER_KEY } from './constant';

export const sessionStorageInfo = { userAuth: LOCAL_STORAGE_USER_KEY };
export function getStoredBrowserUser() {
  try {
    return JSON.parse(
      window.localStorage.getItem(sessionStorageInfo.userAuth) || ''
    );
  } catch (e) {
    return {};
  }
}
export function storeUserToBrowser(payload: any) {
  window.localStorage.setItem(
    sessionStorageInfo.userAuth,
    JSON.stringify(payload)
  );
}
export function getLoginToken() {
  const { type, token, ...rest } = getStoredBrowserUser();
  if (!type || !type || !rest) return false;
  return `${Capitalize(type)} ${token}`;
}

export function clearUserSessionStore() {
  window.localStorage.removeItem(sessionStorageInfo.userAuth);
}

export function logout() {
  clearUserSessionStore();
  window.location.href = getEnv('LOGIN_URL', '/login');
}
export function getImage(picture: any) {
  //  data:{{ picture.format }};base64,{{picture.data.toString('base64')}}
  if (picture && picture.format && picture.data) {
    return `data:${picture.format};base64,${picture.data.toString('base64')}`;
  }
  return '';
}
export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  // @ts-ignore
  const mime = arr[0]?.match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export async function copyTextToClipboard(
  text?: string,
  inputId = 'text-clipboard-input'
) {
  const copyText = document.getElementById(inputId);
  if (copyText && !text) {
    // @ts-ignore
    copyText.select();
    // @ts-ignore
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    // @ts-ignore
    await navigator.clipboard.writeText(copyText.value);
    document.execCommand('copy');
    // @ts-ignore
    alert(`Copied ${copyText.value} to clipboard.`);
  } else if (text) {
    await navigator.clipboard.writeText(text);
    alert(`Hi your sharable url has been copied to clipboard. \n ${text}`);
  }
}

export function getEnv(variable: string, defaultValue: string='') : string {
  if (variable in process.env && process.env[variable]) {
    return process.env[variable] as string;
  }
  return process.env[`REACT_APP_${variable}`] || defaultValue;
}

export const canShowFullscreen = () => {
  // @ts-ignore
  return (
    document.fullscreenEnabled ||
    // @ts-ignore
    document.webkitFullscreenEnabled ||
    // @ts-ignore
    document.mozFullScreenEnabled ||
    // @ts-ignore
    document.msFullscreenEnabled
  );
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
    // @ts-ignore
  } else if (document.mozCancelFullScreen) {
    // @ts-ignore
    document.mozCancelFullScreen();
    // @ts-ignore
  } else if (document.webkitExitFullscreen) {
    // @ts-ignore
    document.webkitExitFullscreen();
    // @ts-ignore
  } else if (document.msExitFullscreen) {
    // @ts-ignore
    document.msExitFullscreen();
  }
};

export const openFullscreen = (element: any = document.body) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

export function encodeUnicode(str: string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes(match, p1) {
      // @ts-ignore
      return String.fromCharCode('0x' + p1);
    }));
}
